import MainLayout from "../Layout/MainLayout"

function Revistas() {
  return (
    <>
    <MainLayout>
    <div>revistas</div>
    </MainLayout>
    </>
  )
}

export default Revistas