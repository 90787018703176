import MainLayout from "../Layout/MainLayout"

function Entrevistas() {
  return (
    <>
    <MainLayout>
    <div>Entrevistas</div>
    </MainLayout>
    </>
  )
}

export default Entrevistas