import MainLayout from "../Layout/MainLayout";
import Inflacion from "../components/Inflacion";

function Economia() {
  return (
    <MainLayout>
      <div>Economia</div>
      <Inflacion />
    </MainLayout>
  )
}

export default Economia