import MainLayout from "../Layout/MainLayout"

function Videoteca() {
  return (
    <>
    <MainLayout>
    <div>Videoteca</div>
    </MainLayout>
    </>
  )
}

export default Videoteca