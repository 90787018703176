import MainLayout from "../Layout/MainLayout"

function Legales() {
  return (
    <MainLayout>
      <div>Legales</div>
    </MainLayout>
  )
}

export default Legales